@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../style/abstract/button';

:root {
    --qty-background: #{$white};
    --qty-button-background-disabled: #{$blue10};
    --qty-button-border-disabled: #{$blue10};
    --qty-input-background: #{$white};
    --qty-border: 1px solid #{$default-primary-base-color};
}
