@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-pilot/src/style/abstract/variables';
@import '../../../../advox-pilot/src/style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-pilot/src/style/abstract/button';

:root {
    --cart-overlay-border-color: #{$default-secondary-base-color};
    --cart-overlay-border-radius: 10px;
    --cart-overlay-promo-border-radius: 5px;
    --cart-overlay-promo-background: #{$default-primary-base-color};
    --cart-overlay-box-shadow-color: rgba(0, 0, 0, 0.15);
    --cart-overlay-scroll-accent-color: #{$default-secondary-base-color};
}

[dir='ltr'] .CartOverlay {
    @include desktop {
        border: 1px solid var(--cart-overlay-border-color);
        border-radius: var(--cart-overlay-border-radius) 0 var(--cart-overlay-border-radius)
            var(--cart-overlay-border-radius);
        overflow: hidden;
        right: -18px;
        top: calc(100% + 11px);
        box-shadow: 0 0 15px 0 var(--cart-overlay-box-shadow-color);
    }

    &:before {
        display: none;
    }

    &-Items {
        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--cart-overlay-scroll-accent-color);
        }

        &::-webkit-scrollbar-track {
            background-color: $grey5;
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }

    &-Promo {
        margin: 18px 18px 0;
        border-radius: var(--cart-overlay-promo-border-radius);
        background: var(--cart-overlay-promo-background);
        min-height: 0;
        border-bottom: none;

        p {
            font-size: 12px;
            font-family: $font-primary;
            font-weight: 700;
            color: $white;
            margin: 0;
        }

        @include tablet {
            margin: 24px 20px 0;
        }

        @include desktop {
            margin: 25px 24px 0;
        }
    }

    &-ContentWrapper {
        border: none;

        @include mobileAndTablet {
            height: 100%;
            display: grid;
            grid-template-rows: 1fr auto;
        }

        .CartItem-Wrapper {
            grid-template-columns: 80px 1fr;
            padding: 16px 0;
            min-height: 128px;

            @include tablet {
                padding: 24px 0;
            }

            @include desktop {
                padding: 18px 0;
            }
        }

        .CartItem-Price_isCartOverlay data {
            @include desktop {
                font-size: 15px;
            }

            @include mobileAndTablet {
                font-size: 20px;
            }
        }
    }

    &-Items {
        padding: 0 12px 0 18px;
        margin-right: 6px;

        @include mobileAndTablet {
            max-height: 100%;
        }

        @include tablet {
            padding: 0 14px 0 20px;
            margin-right: 6px;
        }

        @include desktop {
            padding: 0 18px 0 24px;
            margin-right: 6px;
            max-height: 46vh;
        }
    }

    &-Additional {
        background: $grey-light;

        .AdvoxFreeDelivery {
            display: grid;
            padding: 9px 18px;
            grid-gap: 10px;

            @include tablet {
                padding: 9px 20px;
            }

            @include desktop {
                padding: 9px 24px;
            }
        }
    }

    &-Total {
        background: none;
        padding: 18px 18px 9px;

        @include tablet {
            padding: 24px 20px 9px;
        }

        @include desktop {
            padding: 25px 24px 8px;
        }
    }

    &-Total {
        font-size: 20px;
        line-height: 28px;
        padding: 18px 18px 9px;

        @include tablet {
            padding: 25px 24px 9px;
        }

        @include desktop {
            padding: 25px 24px 8px;
        }

        dt {
            color: $default-primary-base-color;
        }

        dd {
            letter-spacing: 0.7px;
        }

        span {
            font-size: 15px;
            line-height: 18px;
            margin-top: 7px;
            color: $grey-dark;
            letter-spacing: 0;
        }
    }

    &-Actions {
        padding: 9px 18px 18px;
        grid-gap: 16px;

        @include tablet {
            padding: 9px 20px 24px;
            grid-gap: 24px;
        }

        @include desktop {
            padding: 8px 24px 25px;
            grid-gap: 16px;
        }
    }

    &-CartButton {
        &,
        &:hover,
        &:focus {
            height: 48px;
            border-radius: 10px;
            flex-grow: 1;
            width: auto;

            @include mobile {
                display: inline-flex;
            }
        }
    }

    &-CheckoutButton {
        flex-grow: 2;
        width: auto;
        margin: 0;

        svg {
            width: 10px;
            height: 10px;
            margin-left: 10px;
            path {
                stroke: $white;
            }
        }
    }
}

.SwipeableTemporaryDrawer-Drawer {
    .CartOverlay {
        @include mobileAndTablet {
            opacity: 1;
        }
    }
}
